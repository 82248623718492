import React, { useEffect, useMemo, useState } from 'react'
import { Button, Popover, List, ClickAwayListener, ListItem, ListItemText, Box, ListItemButton, ListItemIcon, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { HOME, PROFILE, TEAM, TEST, PRICING, TEAMS, USERS } from '../../_constants/routes'
import photoIcon from '../../assets/DIVERS/avatar_team_member.svg'
import { useNavigate } from 'react-router-dom'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { ArrowLeft, AddCircle } from '@mui/icons-material'
import { ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_MANAGER, TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


const MyMenu = () => {
  
  const { t, i18n } = useTranslation()
  const profile = useAuth().getProfile()
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const dbTeams = teamHooks.getDocs()
  const navigate = useNavigate()
  const userHooks = useFirestore(USER_COLLECTION)
  const auth = useAuth()
  
  const [open, setOpen] = useState(false)
  
  const myTeams = useMemo(() => dbTeams?.filter(t => profile.teamRefs?.some(ref => ref.id === t.id)), [dbTeams, profile])
  
  const displayTeams = useMemo(() => myTeams?.length >= 1 || profile.accessLevel >= ACCESS_LEVEL_MANAGER, [myTeams, profile.accessLevel])
  
  useEffect(() => {
    if (profile.language !== i18n.language) userHooks.updateDoc(profile.id, { language: i18n.language })
  }, [i18n.language])
  
  const onTeamClicked = async teamId => {
    userHooks.updateDoc(profile.id, { currentTeamSelectedRef: teamHooks.getDocRef(teamId) }).then(() => {
      setOpen(false)
      return navigate(profile.accessLevel >= ACCESS_LEVEL_MANAGER ? TEAM : HOME)
    })
  }
  
  return (
    <>
      <Button onClick={() => setOpen(prev => !prev)} id='menuBtn' sx={{
        float: 'right',
        height: '100%',
        width: 80,
        padding: '10px',
        boxSizing: { sm: 'none' },
        '& span': {
          height: { sm: 64, md: 40 },
          width: { sm: 64, md: 40 },
          borderRadius: 32,
          overflow: 'hidden',
          '& img': {
            objectFit: 'cover',
            height: '100%',
          },
        },
      }}>
        {profile.photoUrl
          ? <Box style={{ backgroundImage: `url('${profile.photoUrl}')` }} sx={{
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '100%',
            width: '100%',
          }} />
          : <img src={photoIcon} alt='undefined' />
        }
      </Button>
      {open && <ClickAwayListener onClickAway={() => setOpen(false)}>
        <List
          id='main-menu'
          onClose={() => setOpen(false)}
          role='presentation'
          elevation={0}
          sx={{
            position: 'fixed',
            top: '80px',
            right: 0,
            border: '1px solid',
            borderColor: 'grey.150',
            backgroundColor: 'white',
            padding: '40px 0 40px 10px',
            zIndex: 10,
          }}
        >
          {profile.testResults
            ? <ListItem disablePadding>
              <ListItemButton onClick={() => navigate(HOME)}>
                <ListItemIcon />
                <ListItemText primary={t('menu.cards')} />
              </ListItemButton>
            </ListItem>
            : profile.onboardingStatus === 'done' &&
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate(TEST)}>
                <ListItemIcon />
                <ListItemText primary={t('menu.startTest')} />
              </ListItemButton>
            </ListItem>
          }
          {!profile.companyRef && <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(PRICING)}>
              <ListItemIcon />
              <ListItemText primary={t('menu.inviteTeam')} />
            </ListItemButton>
          </ListItem>}
          {displayTeams && profile.onboardingStatus === 'done' && <PopupState variant='popover' popupId='demo-popup-popover'>
            {popupState => (
              <>
                <ListItem disablePadding>
                  <ListItemButton {...bindTrigger(popupState)}>
                    <ListItemIcon><ArrowLeft sx={{ fill: 'lightgrey', width: '45px', height: '45px' }} /></ListItemIcon>
                    <ListItemText primary={t('menu.teams')} />
                  </ListItemButton>
                </ListItem>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  PaperProps={{
                    sx: {
                      boxShadow: 'none',
                      borderRadius: 0,
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <List sx={{
                    mr: '10px',
                    pl: '30px',
                    border: '1px solid',
                    borderColor: 'grey.150',
                    borderRight: 'none',
                    backgroundColor: 'white',
                  }}>
                    <ListItem disablePadding>
                      <ListItemText primary={t('menu.your-teams')} sx={{
                        color: 'lightgrey',
                        textAlign: 'right',
                      }} />
                    </ListItem>
                    {myTeams && myTeams.filter(t => t.memberRefs.some(ref => ref.id === profile.id)).map(team =>
                      <ListItem
                        key={team.id}
                        sx={{
                          display: 'block',
                          ml: 0,
                          textAlign: 'right',
                          paddingRight: 1,
                        }}
                      >
                        <ListItemButton onClick={() => {popupState.close(); onTeamClicked(team.id)}}>
                          <ListItemText primary={team.name} sx={{
                            border: 'none',
                            p: 0,
                            color: team.id === profile.currentTeamSelectedRef?.id  && 'secondary.main',
                          }} />
                        </ListItemButton>
                      </ListItem>,
                    )}
                    <Divider sx={{ borderColor: 'lightgrey' }} />
                    {profile.accessLevel >= ACCESS_LEVEL_MANAGER &&
                      <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate(TEAMS)}>
                          <ListItemIcon>
                            <AddCircle sx={{
                              fill: theme => theme.palette.tertiary?.main,
                              width: '30px',
                              height: '30px',
                            }} />
                          </ListItemIcon>
                          <ListItemText primary={t('menu.create-team')} sx={{ border: 'none' }} />
                        </ListItemButton>
                      </ListItem>
                    }
                  </List>
                </Popover>
              </>
            )}
          </PopupState>}
          {profile.accessLevel >= ACCESS_LEVEL_ADMIN && <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(USERS)}>
              <ListItemIcon />
              <ListItemText primary='Admin' />
            </ListItemButton>
          </ListItem>}
          {profile.onboardingStatus === 'done' && <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(PROFILE)}>
              <ListItemIcon />
              <ListItemText primary={t('menu.profile')} />
            </ListItemButton>
          </ListItem>}
          {<PopupState variant='popover' popupId='language-popover'>
            {popupState => (
              <>
                <ListItem disablePadding>
                  <ListItemButton {...bindTrigger(popupState)}>
                    <ListItemIcon><ArrowLeft sx={{ fill: 'lightgrey', width: '45px', height: '45px' }} /></ListItemIcon>
                    <ListItemText primary={t('common.language')} />
                  </ListItemButton>
                </ListItem>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  PaperProps={{
                    sx: {
                      boxShadow: 'none',
                      borderRadius: 0,
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <List sx={{
                    mr: '10px',
                    pl: '30px',
                    border: '1px solid',
                    borderColor: 'grey.150',
                    borderRight: 'none',
                    backgroundColor: 'white',
                  }}>
                    <ListItem
                      sx={{
                        display: 'block',
                        ml: 0,
                        textAlign: 'right',
                        paddingRight: 1,
                      }}
                    >
                      <ListItemButton onClick={() => i18n.changeLanguage('fr').then(popupState.close)}>
                        <ListItemText primary='Français' sx={{
                          border: 'none',
                          p: 0,
                          color: i18n.language === 'fr' && 'secondary.main',
                        }} />
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      sx={{
                        display: 'block',
                        ml: 0,
                        textAlign: 'right',
                        paddingRight: 1,
                      }}
                    >
                      <ListItemButton onClick={() => i18n.changeLanguage('en').then(popupState.close)}>
                        <ListItemText primary='English' sx={{
                          border: 'none',
                          p: 0,
                          color: i18n.language === 'en' && 'secondary.main',
                        }} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Popover>
              </>
            )}
          </PopupState>}
          <ListItem disablePadding>
            <ListItemButton onClick={auth.signOut}>
              <ListItemIcon />
              <ListItemText primary={t('menu.logout')} sx={{ borderBottom: 'none' }} />
            </ListItemButton>
          </ListItem>
        </List>
      </ClickAwayListener>}
    </>
  )
}

export default MyMenu
