import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { getRoles, xssCheck } from '../../_helpers/helpers'
import { ACCESS_LEVEL_USER, COMPANY_COLLECTION } from '../../_constants/globals'
import CTAButton from '../_library/FirstLevelButton'
import { REGEX_MAIL } from '../../_constants/regex'
import { PRICING } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'


const NewUserCard = ({ onClose }) => {
  
  const profile = useAuth().getProfile()
  const companyHooks = useFirestore(COMPANY_COLLECTION)
  const company = companyHooks.getDocs()[0]
  const navigate = useNavigate()
  const auth = useAuth()

  const [firstname, setFirstname] = useState(null)
  const [email, setEmail] = useState(null)
  const [accessLevel, setAccessLevel] = useState(ACCESS_LEVEL_USER)
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})

  const handleSubmit = e => {
    e.preventDefault()
    setErrors({})
    if (!company?.licences > 0) setErrors(val => ({ ...val, invites: 'no_invite' }))
    if (!email) setErrors(val => ({ ...val, email: 'Email field must be completed' }))
    else if (!xssCheck(email) || !email.match(REGEX_MAIL))
      setErrors(val => ({ ...val, email: 'L\'email doit être du type email@domain.ext' }))
    if (!firstname) setErrors(val => ({ ...val, firstname: 'Firstname field must be completed' }))
    else if (!xssCheck(firstname)) setErrors(val => ({ ...val, firstname: 'Le prénom n\'est pas valide' }))
    if (company.licences > 0 && xssCheck(email) && email.match(REGEX_MAIL) && xssCheck(firstname)) {
      setSubmitted(true)
      auth.inviteUserAction({
        accessLevel,
        email,
        firstname,
        companyId: company.id,
      })
        .then(onClose)
        .finally(() => setSubmitted(false))
    }
  }

  return (
    <Box sx={{ position: 'relative', bgcolor: 'white', borderRadius: 20, p: 3, mb: 2 }}>
      <Typography variant='body1'>Ajouter un manager. Après, le manager invitera sa propre équipe.</Typography>
      <Button onClick={onClose} sx={{
        position: 'absolute',
        top: theme => theme.spacing(1),
        right: theme => theme.spacing(1),
        color: 'tertiary.main',
        minWidth: 'initial',
        borderRadius: 40,
      }}><Cancel /></Button>
      <Box component='form' onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          label='Prénom'
          value={firstname || ''}
          onChange={e => setFirstname(e.target.value)}
          variant='outlined'
          size='small'
          disabled={submitted}
          error={!!errors.firstname}
          helperText={errors.firstname}
          sx={{ mr: 4, '& label': { fontWeight: 200 }, '& input': { fontWeight: 300 } }}
        />
        <TextField
          label='Email'
          value={email || ''}
          onChange={e => setEmail(e.target.value)}
          variant='outlined'
          size='small'
          disabled={submitted}
          error={!!errors.email}
          helperText={errors.email}
          sx={{ mr: 4, '& label': { fontWeight: 200 }, '& input': { fontWeight: 300 } }}
        />
        <FormControl variant='outlined' disabled={submitted}>
          <InputLabel id='role-select-label'>Role</InputLabel>
          <Select
            labelId='role-select-label'
            id='role-select'
            label='Role'
            value={accessLevel}
            onChange={e => setAccessLevel(e.target.value)}
            sx={{ height: 40 }}
          >
            {getRoles().map(role => profile.accessLevel >= role.accessLevel &&
              <MenuItem key={role.accessLevel} value={role.accessLevel}>{role.title}</MenuItem>,
            )}
          </Select>
        </FormControl>
        <CTAButton type='submit' disabled={submitted || !firstname || !email} sx={{
          float: 'right',
          bgcolor: 'primary.main',
          mr: 0,
          height: 40,
          fontWeight: 500,
          fontSize: '1rem',
        }}>Envoyer L&apos;invitation</CTAButton>
      </Box>
      {errors.invites && <Typography sx={{ color: 'red' }}>
        Vos invitations sont épuisées ! <span onClick={() => navigate(PRICING)}>Commandez ici</span> de nouvelles invitations.
      </Typography>}
    </Box>
  )
}

NewUserCard.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default NewUserCard
