import React, { useEffect, Suspense, useState } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import * as ROUTES from './_constants/routes.js'
import RestrictedArea from './components/_library/RestrictedArea'
import LoginPage from './components/auth/LoginPage'
import Notifications from './components/Notifications'
import LoadingBoussole from './components/_library/LoadingBoussole'
import { CARD_TEMPLATE_COLLECTION, COMPANY_COLLECTION, TEAM_COLLECTION, USER_COLLECTION } from './_constants/globals'
import { listenerRefs } from './firebase'
import useAuth from './hooks/useAuth'
import useFirestore from './hooks/useFirestore'
import { mapValues } from 'lodash'
import DashboardPage from './components/cards/DashboardPage'
import ProfilePage from './components/profile/ProfilePage'
import TeamsPage from './components/teams/TeamsPage'
import TeamPage from './components/teams/team/TeamPage'
import TestPage from './components/test/TestPage'
import CardRouter from './components/cards/CardRouter'
import OnboardingPage from './components/onboarding/OnboardingPage'
import PricingPage from './components/pricing/PricingPage'
import PaymentSummaryPage from './components/pricing/PaymentSummaryPage'
import SelectAvatarPage from './components/onboarding/SelectAvatarPage'
import AdminDashboardPage from './components/users/AdminDashboardPage'
import { useSelector } from 'react-redux'
import PremiumAdCard from './components/_library/PremiumAdCard'
import usePath from './hooks/usePath'
import CampfirePage from './components/campFire/CampfirePage'
import { useTranslation } from 'react-i18next'


export const App = () => {
  
  const consultantMode = useSelector(state => state.data.consultantMode)
  const location = useLocation()
  const path = usePath()
  const profile = useAuth().getProfile()
  const auth = useAuth()
  const cardTemplateHooks = useFirestore(CARD_TEMPLATE_COLLECTION)
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  const companyHooks = useFirestore(COMPANY_COLLECTION)
  const { i18n } = useTranslation()
  
  useEffect(() => {
    auth.listenProfile()
    return () => {
      mapValues(listenerRefs, ({ unsubscribe }) => unsubscribe())
    }
  }, [])
  
  useEffect(() => {
    if (profile.companyRef) {
      cardTemplateHooks.listen()
      companyHooks.listenDoc(profile.companyRef.id)
      return () => {
        cardTemplateHooks.unsubscribe()
        companyHooks.unsubscribe()
      }
    }
  }, [profile?.companyRef])
  
  useEffect(() => {
    if (profile.companyRef) {
      teamHooks.listen({
        where: consultantMode
          ? [['consultantUserRef', '==', userHooks.getDocRef(profile.id)]]
          : [['companyRef', '==', profile.companyRef]],
        orderBy: [['_createdAtTime', 'asc']],
      })
      userHooks.listen({
        where: consultantMode
          ? [['consultantUserRef', '==', userHooks.getDocRef(profile.id)]]
          : [['companyRef', '==', profile.companyRef]],
        orderBy: [['_createdAtTime', 'asc']],
      })
      return () => {
        teamHooks.unsubscribe()
        userHooks.unsubscribe()
      }
    }
  }, [profile?.id, profile?.companyRef, consultantMode])
  
  const [displayAd, setDisplayAd] = useState(true)
  
  useEffect(() => {
    console.log('Environnement: ' + process.env.NODE_ENV + ' ' + process.env.REACT_APP_FIREBASE_PROJECT_ID)
  }, [])
  
  useEffect(() => {window.scrollTo(0, 0)}, [location.pathname])
  
  useEffect(() => {
    if (!['en', 'fr'].includes(i18n.language)) {
      const prev = i18n.language
      i18n.changeLanguage('en').then(() => console.info(`language changed from ${prev} to en`))
    }
  }, [i18n?.language])
  
  return (
    <Suspense fallback={<LoadingBoussole />} >
      <Routes>
        <Route path={ROUTES.LOGIN + '/*'} element={<LoginPage />} />
        <Route exact path={ROUTES.HOME} element={<RestrictedArea authenticated onboarded testFinished avatar><DashboardPage /></RestrictedArea>} />
        <Route exact path={ROUTES.AVATAR} element={<RestrictedArea authenticated onboarded testFinished><SelectAvatarPage /></RestrictedArea>} />
        <Route path={ROUTES.CARD + '/*'} element={<RestrictedArea authenticated><CardRouter /></RestrictedArea>} />
        <Route path={ROUTES.ONBOARDING} element={<RestrictedArea authenticated><OnboardingPage /></RestrictedArea>} />
        <Route exact path={ROUTES.PAYMENT_SUMMARY} element={<RestrictedArea authenticated><PaymentSummaryPage /></RestrictedArea>} />
        <Route exact path={ROUTES.PRICING} element={<RestrictedArea authenticated><PricingPage /></RestrictedArea>} />
        <Route path={ROUTES.PROFILE + '/*'} element={<RestrictedArea authenticated><ProfilePage /></RestrictedArea>} />
        <Route exact path={ROUTES.TEAMS} element={<RestrictedArea authenticated freemiumOrManager><TeamsPage /></RestrictedArea>} />
        <Route exact path={ROUTES.TEAM} element={<RestrictedArea authenticated freemiumOrManager><TeamPage /></RestrictedArea>} />
        <Route path={ROUTES.TEST} element={<RestrictedArea authenticated onboarded><TestPage /></RestrictedArea>} />
        <Route exact path={ROUTES.USERS} element={<RestrictedArea authenticated admin><AdminDashboardPage /></RestrictedArea>} />
        <Route exact path={ROUTES.CAMPFIRE} element={<RestrictedArea authenticated><CampfirePage /></RestrictedArea>} />
        <Route path='*' element={<Navigate replace to={ROUTES.HOME} />} />
      </Routes>
      <Notifications />
      {path.displayTeamSectionCards && !profile.companyRef && displayAd && <PremiumAdCard onClose={() => setDisplayAd(false)} />}
    </Suspense>
  )
}

export default App
