import React from 'react'
import PropTypes from 'prop-types'
import { Backdrop, useTheme, useMediaQuery, Box, Typography, Button } from '@mui/material'
import OutlinedButton from '../_library/OutlinedButton'
import CTAButton from '../_library/FirstLevelButton'
import WarningIcon from '../../assets/DIVERS/warning-icon.svg'
import TeamListIcon from '../../assets/DIVERS/teamlist-icon.svg'
import { Close } from '@mui/icons-material'
import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'


export const BACKDROP_TYPE = {
  noBackdrop: 0,
  transparentGreen: 1,
  opaqueGreen: 2,
}

const Balloon = ({ data, preset, onClick, style, styleTitle, backdropType = BACKDROP_TYPE.transparentGreen, onCTA, onClose }) => {
  
  const profile = useAuth().getProfile()
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  
  const { title, text, cta, button, bold, icon } = preset ? t('dashboard.balloon-presets.' + preset) : data
  
  const content =
    <Box
      style={style}
      sx={{
        lineHeight: '1.8rem',
        width: { sm: '430px' },
        maxWidth: '100%',
        height: { sm: '430px' },
        position: { sm: 'absolute'  },
        left: { sm: '50%' },
        top: { sm: '50%' },
        mt: { sm: '-200px' },
        ml: { sm: '-215px' },
        borderRadius: '50%',
        color: 'primary.main',
        bgcolor: 'white',
        py: { sm: '100px', md: '60px' },
      }}
    >
      <Box sx={{ textAlign: 'center', position: 'absolute', top: '50%', transform: 'translate(0, -50%)', maxWidth: '350px', mx: { xs: '5vw', sm: '40px' } }}>
        {isDownSm && preset === 'lockedCategory' && <Button onClick={onClose} sx={{ color: 'black', position: 'absolute', top: '-50px', right: 0 }}><Close /></Button>}
        {isDownSm && icon === 'warning' && <Box component='img' sx={{ maxWidth: 45, maxHeight: 45, m: 1 }} src={WarningIcon} alt='Warning icon' />}
        {isDownSm && icon === 'team-list' && <Box component='img' sx={{ maxWidth: 45, maxHeight: 45, m: 1 }} src={TeamListIcon} alt='Team List icon' />}
        <Typography
          variant='h3'
          fontWeight='750'
          mb='0.7rem'
          mt={{ xs: 0, sm: '0.2rem' }}
          pt={{ xs: 0, sm: 'initial' }}
          fontSize={{ xs: '1.5rem', sm: '2.2rem' }}
          {...styleTitle}
        >{title?.replace('FIRSTNAME', profile.firstname)}</Typography>
        <Typography variant='body1' color='primary.dark'>{text?.replace('FIRSTNAME', profile.firstname)}</Typography>
        {bold && <Typography fontWeight='bold' color='primary.dark'>{bold}</Typography> }
        {cta && <CTAButton onClick={onCTA} sx={{ fontSize: '0.92rem', mx: 'auto', mt: 2 }}>{cta}</CTAButton>}
        {button && <OutlinedButton onClick={onClick} sx={{
          p: '5px 20px',
          mt: { xs: 3, sm: 2 },
          mb: { xs: 3, sm: 1 },
          fontSize: '0.92rem',
          fontWeight: 700,
        }}>{button}</OutlinedButton>}
      </Box>
    </Box>
  
  if (backdropType === BACKDROP_TYPE.noBackdrop && !isDownSm)
    return content
  else
    return (
      <Backdrop open sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
        bgcolor: { xs: 'rgb(255, 255, 255, 1)', sm: backdropType === BACKDROP_TYPE.opaqueGreen ? 'rgb(61, 173, 173, 1)' : 'rgb(61, 173, 173, .85)' },
        display: { xs: 'block', sm: 'flex' },
      }}>
        {content}
      </Backdrop>
    )
}

Balloon.propTypes = {
  data: PropTypes.object,
  preset: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  styleTitle: PropTypes.object,
  backdropType: PropTypes.number,
  onCTA: PropTypes.func,
  onClose: PropTypes.func,
}

export default Balloon
